import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusFourStep, FourStepsEdges } from './four-steps.interface';

export default (): FourStepsEdges[] => {
  const { allDirectusFourStep } = useStaticQuery<AllDirectusFourStep>(graphql`
    query {
      allDirectusFourStep(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            heading
            content
            number
            image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  return allDirectusFourStep.edges;
};
