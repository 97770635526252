import React from 'react';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons/faChevronRight';
import scrollTo from 'gatsby-plugin-smoothscroll';

import Button from 'components/common/button/Button';
import ButtonGroup from 'components/common/button-group/ButtonGroup';
import Markdown from 'components/common/markdown/Markdown';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import Carousel from 'components/directus/carousel/Carousel';
import fourStepHook from 'hooks/four-steps/four-steps.hook';
import { getFieldBySlug } from 'utils/directus';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { StepBlock } from './FourSteps.interface';

import {
  fourSteps__step,
  fourSteps__image,
  fourSteps__body,
  fourSteps__number,
  fourSteps__heading,
  fourSteps__content,
  fourSteps__icon,
  fourSteps,
  fourStepsMobile,
  fourSteps__cta,
} from './FourSteps.scss';

const StepContainer = ({ image, heading, content, step }: StepBlock) => (
  <div className={fourSteps__step}>
    <div className={fourSteps__image}>
      <Img
        fluid={image.localFile.childImageSharp.fluid}
        alt={`${heading} four step image.`}
        style={{ width: `${image.width / 2}px`, height: `${image.height / 2}px`, margin: '0 auto' }}
        fadeIn
      />
    </div>

    <div className={fourSteps__body}>
      <div className={fourSteps__number}>{step}</div>
      <h4 className={fourSteps__heading}>
        <Markdown source={heading} />
      </h4>
      <Markdown source={content} className={fourSteps__content} container />
    </div>

    {step < 4 && (
      <div className={fourSteps__icon}>
        <FontAwesomeIcon icon={faChevronRight} />
      </div>
    )}
  </div>
);

export default (): JSX.Element => {
  const steps = fourStepHook();
  const { node } = getFieldBySlug('four-step', sectionTitleHook());

  return (
    <>
      <SectionTitle title={node.heading} subtitle={node.subheading} align="center" />
      <div className={fourSteps}>
        {steps.map(
          ({ node }): JSX.Element => (
            <StepContainer
              key={`four-steps-${node.directusId}`}
              image={node.image}
              heading={node.heading}
              content={node.content}
              step={node.number}
            />
          )
        )}
      </div>
      <div className={fourStepsMobile}>
        <Carousel width={300} height={225} maxVisibleSlides={1} changeOnResize>
          {steps.map(
            ({ node }): JSX.Element => (
              <StepContainer
                key={`four-steps-mobile-${node.directusId}`}
                image={node.image}
                heading={node.heading}
                content={node.content}
                step={node.number}
              />
            )
          )}
        </Carousel>
      </div>
      <ButtonGroup block className={fourSteps__cta}>
        <Button role="primary" size="large" onClick={() => scrollTo('#home-top')}>
          Register Now
        </Button>
      </ButtonGroup>
    </>
  );
};
